import { getSuppliersByUser, getCategoryOneRecord, postCategoryOne, putCategoryOneRecord, deleteCategoryOneRecord } from '@/api/dataentry-api-generated';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SaveDialog from '@/components/SaveDialog.vue';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'CategoryOneComponent',
    components: { DeleteDialog, SaveDialog },
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            effectiveDates: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            suppliers: [],
            item: {},
            supplierOptions: [{
                    label: '',
                    value: ''
                }],
            supplierDisplay: {
                label: '',
                value: ''
            }
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        selectedItems() {
            return this.propSelected;
        },
        buttonLabel() {
            return 'SAVE';
        }
    },
    created() {
        this.getSuppliers();
        if (!this.createNew) {
            this.getItem(this.uuid, true);
        }
        if (this.duplicateRecord) {
            if (this.id !== undefined) {
                this.getItem(this.id, false);
            }
        }
    },
    methods: {
        async getItem(uuid, isUpdate) {
            this.loading = true;
            try {
                const result = await getCategoryOneRecord(uuid);
                this.item = result;
                if (isUpdate) {
                    this.supplierDisplay.label = result.categoryOneSupplierLabel;
                    this.supplierDisplay.value = result.supplier;
                }
                this.loading = false;
            }
            catch (error) {
                const errMsg = error;
                if (errMsg.message.includes('ResourceNotFoundException')) {
                    this.closeDialog();
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        saveItem() {
            if (!this.createNew) {
                this.updateItem();
            }
            else {
                this.createItem();
            }
        },
        async updateItem() {
            // Validate the form before proceeding
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                try {
                    req.supplier = this.supplierDisplay.value;
                    await putCategoryOneRecord(this.uuid, this.item);
                    this.$log.addMessage('Successfully updated Category 1 record');
                    this.$router.push({ name: 'Cat. 1 - Purchased Goods & Services Records' });
                }
                catch (error) {
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.supplier = this.supplierDisplay.value;
                try {
                    await postCategoryOne(req);
                    this.$log.addMessage('Successfully created new Category 1 record');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Category 1 details already exists: <a href="#/category-one/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        async deleteItem() {
            try {
                const result = await deleteCategoryOneRecord(this.uuid);
                this.item = result;
                this.$log.addMessage('Successfully Deleted Category 1 Record');
                this.closeDialog();
            }
            catch (error) {
                this.$log.addError(error);
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Cat. 1 - Purchased Goods & Services Records' });
        },
        async getSuppliers() {
            this.loading = true;
            try {
                const result = await getSuppliersByUser({ pageSize: 1000000, deleted: false });
                this.suppliers = result.items;
                this.supplierOptions = this.getSupplierOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getSupplierOptions() {
            return this.suppliers.map(i => {
                return {
                    label: i.supplierLabel,
                    value: i.uuid
                };
            });
        },
        filterOptions(val, update) {
            if (val === '') {
                update(() => {
                    // No filter - display default list
                    this.supplierOptions = this.getSupplierOptions();
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.supplierOptions = this.getSupplierOptions().filter(v => v.label.toLowerCase().indexOf(needle) > -1);
            });
        }
    }
});
