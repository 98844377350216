import { defineComponent } from 'vue';
import CategoryOneComponent from '@/components/CategoryOne/CategoryOneComponent.vue';
export default defineComponent({
    name: 'CategoryOneDuplicateDialog',
    props: {
        propSelected: Array,
        propId: String
    },
    components: { CategoryOneComponent },
    data: function () {
        return {
            show: false,
            id: this.propId
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});
