import { defineComponent } from 'vue';
import CategoryOneRecords from '@/views/CategoryOne/CategoryOneRecords.vue';
export default defineComponent({
    name: 'CategoryOneLayout',
    components: { CategoryOneRecords },
    data() {
        return {
            loading: false,
            tab: 'Cat. 1 - Purchased Goods & Services Records'
        };
    }
});
