import { defineComponent } from 'vue';
import CategoryOneComponent from '@/components/CategoryOne/CategoryOneComponent.vue';
export default defineComponent({
    name: 'CategoryOneDialog',
    props: {
        propSelected: Array
    },
    components: { CategoryOneComponent },
    data: function () {
        return {
            show: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});
