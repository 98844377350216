import { render } from "./CategoryOneComponent.vue?vue&type=template&id=fc6dd620&scoped=true"
import script from "./CategoryOneComponent.vue?vue&type=script&lang=ts"
export * from "./CategoryOneComponent.vue?vue&type=script&lang=ts"

import "./CategoryOneComponent.vue?vue&type=style&index=0&id=fc6dd620&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-fc6dd620"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QCard,QCardSection,QForm,QSelect,QItem,QItemSection,QInput});
